import React from "react"
import classnames from 'classnames/bind'

// Components
import Layout from '@/components/common/Layout'

// Lib MISC
import '@/styles/main.scss'
import styles from '@/styles/static.scss'

const cx = classnames.bind(styles)

export default function CookieStatement ({ lang = 'en' }) {
  return <Layout lang={lang}>
    <div className={cx('static-banner')}>
      <p className={cx('static-banner-title')}>Cookies Statement</p>
    </div>
    <div className={cx('static-content')}>
      <p>We use cookies, web beacons and similar technologies (jointly referred to as “cookies”) to improve your experience with us. We use cookies on our websites. This cookie statement explains how we use cookies and your choices regarding their usage.</p>
      <br />
      <p>Please also see our Privacy Policy, <a href={`https://re.cards/${lang}/PrivacyPolicy.html`}>{`https://re.cards/${lang}/PrivacyPolicy.html`}</a> with regards to the use of your personal data.</p>
      <br />
      <p>What are cookies?</p>
      <p>Cookies are small text files consisting of letters and numbers. These are sent from ReCards’s or our partners’ web servers and stored on your device as described below. We use several types of cookies:</p>
      <li>Session cookies are temporary cookie files that expire when you close your browser or app</li>
      <li>Persistent cookies are cookie files that remain until you erase them, or they expire</li>
      <li>First-party cookies are cookies set by the site you are visiting</li>
      <li>Third-party cookies are cookies set by a third-party site</li>
      <li>Web beacons are small transparent graphic images that may be in emails we send to you.</li>
      <br />
      <p>Similar technologies are technologies that store information in your browser or on your device in a manner similar to cookies and web beacons.</p>
      <br />
      <p>Why do we use cookies?</p>
      <p>The cookies we use typically enhance our services towards you. Some of our pages actually need cookies to work, others simply make our website all the smoother for you. In general, we categorize our cookies and their usage as follows:</p>
      <br />
      <p>Optimization cookies provide high-level analytical information on your use of our website. They permit us to continuously refine our website and as such are also necessary. Our website would not be as smooth without these cookies:</p>
      <li>Preference cookies, which allow us to store preferences, such as your language of choice or whether to pre-fill your data or not. Without these types of cookies, we would not be able to personalize our website according to your preferences. These cookies are necessary, as making your experience with us as smooth as possible is a core part of our website</li>
      <li>Security related cookies keep our website and your data safe and secure, such as by helping us detect fraud and guarding your data. Since this is an essential aspect of our website, these cookies are necessary Marketing cookies</li>
      <li>Marketing Cookies, ReCards uses marketing cookies to personalize and improve your experience on our website, in marketing emails, and in third-party advertising.</li>
      <br />
      <p>Marketing automation: Cookies from our Marketing Automation platform enables us to be more relevant in our communication with you. Based on personal data volunteered by the user, e.g. via forms on our website, ReCards use this information to improve the ability to deliver more relevant information on the website, and through advertising channels</p>
      <br />
      <p>Analytics: Analytics cookies collect information on how users interact with our website, providing us with learnings of overall interaction patterns rather than single user behavior. This data helps us improve your experience on the website</p>
      <br />
      <p>Advertising: Online advertising cookies collect information on your activities and shown interest across our website enabling us to display relevant, targeted advertising in third party channels (search engines, social media and display advertising networks)</p>
      <br />
      <p>How can you control ReCards’s use of cookies?</p>
      <p>Your browser or device will typically offer settings regarding the use and scope of cookies. Please see your browser’s or device’s reference information to learn how to adjust your settings. For example, you may be able to block all cookies, accept only first-party cookies, or delete all cookies upon exiting your browser.</p>
      <br />
      <p>Please be aware that some services we offer will not work if you block or delete cookies.</p>
      <br />
      <p>Contact us</p>
      <p>Red Soldier Limited is a company incorporated and registered in Hong Kong whose registered office is at Unit 1201-1204, Texwood Plaza, No.6 How Ming Street, Kwun Tong, Kowloon, Hong Kong.</p>
      <br />
      <p>ReCards is the responsible entity (controller) for the processing of your personal data for handling your account as a Customer and as a (processor) when handling the platform and services as described above. ReCards is subject to Hong Kong data protection legislation.</p>
      <br />
      <p>Revised: Nov 16, 2021.</p>
    </div>
  </Layout>
}